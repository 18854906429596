/* eslint-disable */

import React, { Component } from "react";
import {
  Container,
  Segment,
  Header,
  Icon,
  Divider,
  Form,
  Input,
  Grid,
  Button,
  Label,
  Dropdown,
  Message
} from "semantic-ui-react";
import axios from "axios";
import { DateInput } from 'semantic-ui-calendar-react';
// import { Link } from "react-router-dom";
// import { Redirect } from "react-router";
import { connect } from "react-redux"; // connect redux
import { reduxSource, tempAddressFormProductRegister } from "../actions/actions";
import { PRODUCT_TYPE, getListSuggestion } from "../router";
import Headers from '../header/requestHeader';
import { GET_PROVINCE, GET_DISTRICT, GET_SUB_DISTRICT, STANDARD, FACTORY_AREA } from '../router';
import Swal from 'sweetalert2';
import moment from 'moment'

const theme_form_color_1 = "violet";
const theme_form_color_2 = "teal";
const theme_button_color = "violet";

// export default inAreaFactory;
const mapStateToProps = function (state) {
  return {
    message: "This is message from mapStateToProps",
    stateRedux: state.reduxxx,
    optionDropdown: state.reduxxx.optionAddressProductRegister
  };
};

class productRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      factory_pos: 0,
      product_type_options: [],
      product_structure_options: [],
      suggestion_option:[],
      formProductError: false,
      factory_form_obj: [
        {
          factories_id: '',
          factory_name_th: "",
          factory_name_en: "",
          factory_license_number: "",
          factory_certificate: "",
          factory_from: "",
          factory_under: "",
          factory_location: "",
          factory_number: "",
          factory_alley: "",
          factory_road: "",
          factory_province: "",
          factory_district: "",
          factory_zone: "",
          factory_zip_code: "",
          factory_tel: "",
          factory_license_file: { name: "", data: [], path: "" },
          factory_certificate_file: { name: "", data: [], path: "" },
          factory_product: [
            {
              product_id: '',
              product_name: "",
              product_structure: "",
              product_power_output: "",
              product_trade_name: [
                {
                  brand_id: '',
                  trade_name: ""
                }
              ]
            }
          ],
          isIndustrial: "out",
          posIndustrial: "",
        }
      ],
      initialIndex: 0,
      factory_location_options: [
        { value: "out", text: "นอกเขตนิคมอุตสาหกรรม" },
        { value: "in", text: "ในเขตนิคมอุตสาหกรรม" }
      ],


    };
  }
  // Set State Redux
  goNextRedux = () => {
    this.setState({ forwardProductImport: true }, () => {
      this.props.dispatch(tempAddressFormProductRegister(this.state.optionDropdown));
      this.props.dispatch(reduxSource(this.state));
    })

    // console.log("SET REDUX");
  };

  componentWillMount() {
    this.setState({ optionDropdown: this.props.optionDropdown })
    this.setState(this.props.stateRedux);
    this.GetStandard();
    this.GetFactoryArea();
    this.GetProvince(0);
    window.scrollTo(0, 0);

  }


  componentDidMount = async () => {
    // this.state.factory_form_obj[0].posIndustrial = this.state.factory_in_location_options[this.state.index_factory_location_options[0]].value
    // ดึง API เอา options ของ product type
    var { data } = await axios.get(PRODUCT_TYPE);
    let product_type = [];
    let product_structure = []
    data.value.forEach((data, index) => {
      let obj = {
        key: index + 1,
        text: data.name,
        value: data.name
      };
      product_type.push(obj);
      let type_struct = [];
      data.type_structs.map((data, index) => {
        let obj = {
          key: index + 1,
          text: data.name,
          value: data.name
        };
        type_struct.push(obj);
      });
      product_structure.push(type_struct);
    });
    this.setState({
      product_type_options: product_type, product_structure_options: product_structure
    });
    this.getOptionsSuggestion()


    // console.log(this.state.product_structure_options);  
    // console.log(product_type);
  };

  getOptionsSuggestion = async() =>{
    let response = await getListSuggestion();
    let new_option = response?.value?.brands_suggestion?.map(option => ({
      key: option.id,
      text: option.name,
      value: option.name
    }));
    this.setState({ suggestion_option: new_option })
  }

  GetStandard = () => {
    let datastandard = [];
    axios.get(STANDARD).then((response) => {
      response.data.value.map((data) => {
        let obj = {
          key: data.id,
          text: data.name,
          value: data.name
        };
        datastandard.push(obj);
      });
      this.setState({ datastandard: datastandard });
    });
  }

  GetFactoryArea = () => {
    let datafactory = [];
    axios.get(FACTORY_AREA).then((response) => {
      response.data.value.map((data) => {
        let obj = {
          key: data.id,
          text: data.name,
          value: {
            pos: data.id,
            name_pos: data.name,
            zone: data.subdistrict,
            district: data.district,
            province: data.province,
            zip_code: data.zipcode
          }

        };
        datafactory.push(obj);
      });
      // console.log('data fact', this.state.factory_form_obj)

      this.setState({ factory_in_location_options: datafactory }, () => {
        this.state.factory_form_obj.map((factory, index) => {
          if (factory.isIndustrial === 'in') {
            factory.posIndustrial = this.state.factory_in_location_options[this.state.index_factory_location_options[index]].value

          } else {
            factory.posIndustrial = { pos: 0 }
          }
        })
      });

      // console.log('state fac', this.state.factory_form_obj)
    });
  }


  GetProvince = (index) => {
    axios
      .get(GET_PROVINCE)
      .then((res) => {
        const data = res.data.value;
        let dataprovince = data.map((i) => ({
          key: i.id,
          text: i.name_th,
          value: i.name_th
        }));
        this.state.optionDropdown.dataprovince[index] = dataprovince;
        this.setState({
          dataprovince: this.state.dataprovince
        });
      })
      .catch((error) => { });
  };

  GetDistrict = (value, index) => {
    try {
      axios.get(GET_DISTRICT + '?province_id=' + value).then((res) => {
        const data = res.data.value;
        let datadistrict = data.map((i) => ({
          key: i.id,
          text: i.name_th,
          value: i.name_th
        }));
        this.state.optionDropdown.datadistrict[index] = datadistrict;
        this.setState({
          datadistrict: this.state.datadistrict
        });
      });
    } catch (error) { }
  };

  GetSubDistrict = async (value, index) => {
    axios.get(GET_SUB_DISTRICT + '?amphure_id=' + value).then((res) => {
      const data = res.data.value;
      let dataSubDistrict = data.map((i) => ({
        key: i.id,
        text: i.name_th,
        value: i.name_th
      }));
      this.state.optionDropdown.dataSubDistrict[index] = dataSubDistrict;
      this.setState({
        dataSubDistrict: this.state.dataSubDistrict,
        tempSubDistrict: data
      });
    });
  };

  GetPostCode = (value, index) => {
    let zipcode = this.state.tempSubDistrict.find((data) => {
      return data.id === value;
    });
    if (zipcode !== undefined) {
      this.setState((state) => (state.factory_form_obj[index].factory_zip_code = zipcode.zip_code));
    }
    else {
      this.setState((state) => (state.person[index].zip_code = ''));
    }
  };

  appendForm = async () => {
    var form_validate = await this.validFormByObj(
      this.state.factory_form_obj[this.state.factory_pos]
    );

    if (form_validate) {
      await this.setState({ initialIndex: this.state.initialIndex + 1 }, () => {
        this.GetProvince(this.state.initialIndex);
      });
      await this.setState({
        factory_pos: this.state.factory_pos + 1,
        formProductError: false,
        factory_form_obj: [
          ...this.state.factory_form_obj,
          {
            factories_id: '',
            factory_name_th: "",
            factory_name_en: "",
            factory_license_number: "",
            factory_certificate: "",
            factory_from: "",
            factory_under: "",
            factory_location: "",
            factory_number: "",
            factory_alley: "",
            factory_road: "",
            factory_province: "",
            factory_district: "",
            factory_zone: "",
            factory_zip_code: "",
            factory_tel: "",
            factory_license_file: { name: "", data: [], path: "" },
            factory_certificate_file: { name: "", data: [], path: "" },

            factory_product: [
              {
                product_id: '',
                product_name: "",
                product_structure: "",
                product_power_output: "",
                product_trade_name: [
                  {
                    brand_id: '',
                    trade_name: ""
                  }
                ]
              }
            ],

            isIndustrial: "out",
            posIndustrial: ""
          }
        ],

      });
      this.state.index_factory_location_options.push(0)
      this.setState()
      await document.getElementById("headFactory").scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  nextForm = async () => {
    var form_validate = await this.validFormByObj(
      this.state.factory_form_obj[this.state.factory_pos]
    );

    if (form_validate) {
      await this.setState({
        factory_pos: this.state.factory_pos + 1
      });
      document.getElementById("headFactory").scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  backForm = async () => {
    var form_validate = await this.validFormByObj(
      this.state.factory_form_obj[this.state.factory_pos]
    );

    if (form_validate) {
      await this.setState({
        factory_pos: this.state.factory_pos - 1
      });
      document.getElementById("headFactory").scrollIntoView({
        behavior: "smooth"
      });
    }
  };

  deleteIndustrial = async () => {
    var pos = this.state.factory_pos;
    pos -= 1;
    let tmp = this.state.factory_form_obj;
    tmp.splice(this.state.factory_pos, 1);
    await this.setState({
      factory_pos: pos,
      factory_form_obj: tmp
    });
  };

  appendProduct = async () => {
    var tmp = this.state.factory_form_obj;

    tmp[this.state.factory_pos].factory_product.push({
      product_id: '',
      product_name: "",
      product_structure: "",
      product_power_output: "",
      product_trade_name: [
        {
          brand_id: '',
          trade_name: ""
        }
      ]
    });

    await this.setState({
      factory_form_obj: tmp
    });
  };

  deleteProduct = async index => {
    var tmp = this.state.factory_form_obj;

    tmp[this.state.factory_pos].factory_product.splice(index, 1);
    await this.setState({
      factory_form_obj: tmp
    });
  };

  deleteTradeName = async (productIndex, tradeIndex) => {
    var tmp = this.state.factory_form_obj;

    tmp[this.state.factory_pos].factory_product[
      productIndex
    ].product_trade_name.splice(tradeIndex, 1);

    await this.setState({
      factory_form_obj: tmp
    });
  };

  previousForm = () => {
    this.props.history.push(`/company/editEntrepreneur/entrepreneur`);
  };

  appendTradeName = async index => {
    var tmp = this.state.factory_form_obj;
    tmp[this.state.factory_pos].factory_product[index].product_trade_name.push({
      brand_id: '',
      trade_name: ""
    });
    await this.setState({
      factory_form_obj: tmp
    });
  };

  validFormByObj = async obj => {
    let valid = 0;
    let count = 0;

    var tmp = this.state.factory_form_obj;

    if (tmp) {
      for (
        let index_product = obj.factory_product.length - 1;
        index_product >= 0;
        index_product--
      ) {
        let product = obj.factory_product[index_product];

        valid += 3;

        // validate ผลิตภัณฑ์
        if (product.product_name === "") {
          document
            .getElementById(
              "product_name_" + this.state.factory_pos + "_" + index_product
            )
            .focus();
          tmp[this.state.factory_pos].factory_product[
            index_product
          ].product_name_error = true;
          await this.setState({
            factory_form_obj: tmp
          });
        } else {
          tmp[this.state.factory_pos].factory_product[
            index_product
          ].product_name_error = false;
          await this.setState({
            factory_form_obj: tmp
          });
          count++;
        }

        // validate โครงสร้าง
        if (product.product_structure === "") {
          tmp[this.state.factory_pos].factory_product[
            index_product
          ].product_structure_error = true;
          await this.setState({
            factory_form_obj: tmp
          });
        } else {
          tmp[this.state.factory_pos].factory_product[
            index_product
          ].product_structure_error = false;
          await this.setState({
            factory_form_obj: tmp
          });
          count++;
        }

        // validate กำลังการผลิตต่อปี
        if (product.product_power_output === "") {
          // product.product_power_output_focus.focus();
          document
            .getElementById("product_power_output_" + this.state.factory_pos)
            .focus();
          tmp[this.state.factory_pos].factory_product[
            index_product
          ].product_power_output_error = true;
          await this.setState({
            factory_form_obj: tmp
          });
        } else {
          tmp[this.state.factory_pos].factory_product[
            index_product
          ].product_power_output_error = false;
          await this.setState({
            factory_form_obj: tmp
          });
          count++;
        }

        for (
          let index_trade = product.product_trade_name.length - 1;
          index_trade >= 0;
          index_trade--
        ) {
          valid += 1;
          let trade = product.product_trade_name[index_trade];

          // validate ชื่อทางการค้า
          if (trade.trade_name === "") {
            document
              .getElementById("trade_name_" + this.state.factory_pos)
              .focus();

            tmp[this.state.factory_pos].factory_product[
              index_product
            ].product_trade_name[index_trade].trade_name_error = true;
            await this.setState({
              factory_form_obj: tmp
            });
          } else {
            tmp[this.state.factory_pos].factory_product[
              index_product
            ].product_trade_name[index_trade].trade_name_error = false;
            await this.setState({
              factory_form_obj: tmp
            });
            count++;
          }
        }
      }

      // validate ใบรับรองระบบบริหารงานคุณภาพ certificate_file
      if (obj.factory_certificate_file.name === "") {
        document
          .getElementById("factory_certificate_file_focus")
          .scrollIntoView();
        tmp[this.state.factory_pos].factory_certificate_file_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        // console.log("ใบรับรองระบบบริหารงานคุณภาพ", obj.factory_certificate_file);
        tmp[this.state.factory_pos].factory_certificate_file_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      // validate ใบอนุญาตประกอบกิจการโรงงาน license_file
      if (obj.factory_license_file.name === "") {
        document.getElementById("factory_license_file_focus").scrollIntoView();
        tmp[this.state.factory_pos].factory_license_file_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        // console.log("ใบอนุญาตประกอบกิจการโรงงาน", obj.factory_license_file);
        tmp[this.state.factory_pos].factory_license_file_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      // validate เบอร์โทรศัพท์ factory_tel
      if (obj.factory_tel === "") {
        document
          .getElementById("factory_tel_" + this.state.factory_pos)
          .focus();
        tmp[this.state.factory_pos].factory_tel_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        // console.log("เบอร์โทรศัพท์:", obj.factory_tel);
        tmp[this.state.factory_pos].factory_tel_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      // validate รหัสไปรษณีย์ factory_zip_code
      if (obj.factory_zip_code === "") {
        document
          .getElementById("factory_zip_code_" + this.state.factory_pos)
          .focus();
        tmp[this.state.factory_pos].factory_zip_code_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        // console.log("รหัสไปรษณีย์:", obj.factory_zip_code);
        tmp[this.state.factory_pos].factory_zip_code_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      // validate ตำบล/แขวง factory_zone
      if (obj.factory_zone === "") {
        document
          .getElementById("factory_zone_" + this.state.factory_pos)
          .focus();
        tmp[this.state.factory_pos].factory_zone_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        // console.log("ตำบล/แขวง:", obj.factory_zone);
        tmp[this.state.factory_pos].factory_zone_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      // validate อำเภอ/เขต factory_zone
      if (obj.factory_district === "") {
        document
          .getElementById("factory_district_" + this.state.factory_pos)
          .focus();
        tmp[this.state.factory_pos].factory_district_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        // console.log("ตำบล/แขวง:", obj.factory_district);
        tmp[this.state.factory_pos].factory_district_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      // validate จังหวัด factory_zone
      if (obj.factory_province === "") {
        document
          .getElementById("factory_province_" + this.state.factory_pos)
          .focus();
        tmp[this.state.factory_pos].factory_province_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        // console.log("จังหวัด:", obj.factory_province);
        tmp[this.state.factory_pos].factory_province_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      // validate ถนน factory_road
      if (obj.factory_road === "") {
        document
          .getElementById("factory_road_" + this.state.factory_pos)
          .focus();
        tmp[this.state.factory_pos].factory_road_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        // console.log("ถนน:", obj.factory_road);
        tmp[this.state.factory_pos].factory_road_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      // validate ซอย factory_alley
      if (obj.factory_alley === "") {
        document
          .getElementById("factory_alley_" + this.state.factory_pos)
          .focus();
        tmp[this.state.factory_pos].factory_alley_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        // console.log("ซอย:", obj.factory_alley);
        tmp[this.state.factory_pos].factory_alley_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      // validate เลขที่ factory_alley
      if (obj.factory_number === "") {
        document
          .getElementById("factory_number_" + this.state.factory_pos)
          .focus();
        tmp[this.state.factory_pos].factory_number_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        // console.log("เลขที่:", obj.factory_number);
        tmp[this.state.factory_pos].factory_number_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      // console.log("นิคมอุตสหกรรม:", obj.posIndustrial.name_pos);
      // console.log("ที่ตั้งโรงงาน:", obj.isIndustrial);

      // validate ภายใต้ factory_under
      if (obj.factory_under === "") {
        document
          .getElementById("factory_under_" + this.state.factory_pos)
          .focus();
        tmp[this.state.factory_pos].factory_under_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        // console.log("ภายใต้:", obj.factory_under);
        tmp[this.state.factory_pos].factory_under_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      // validate จาก factory_from
      if (obj.factory_from === "") {
        document
          .getElementById("factory_from_" + this.state.factory_pos)
          .focus();
        tmp[this.state.factory_pos].factory_from_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        tmp[this.state.factory_pos].factory_from_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      if (obj.factory_certificate === "") {
        document
          .getElementById("factory_certificate")
          .focus();

        tmp[this.state.factory_pos].factory_certificate_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        tmp[this.state.factory_pos].factory_certificate_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      if (obj.factory_expire_certificate === "") {
        document
          .getElementById("factory_expire_certificate")
          .focus();

        tmp[this.state.factory_pos].factory_expire_certificate_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        tmp[this.state.factory_pos].factory_expire_certificate_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      // validate เลขที่ใบอนุญาตประกอบกิจการ factory_license_number
      if (obj.factory_license_number === "") {
        document
          .getElementById("factory_license_number_" + this.state.factory_pos)
          .focus();
        tmp[this.state.factory_pos].factory_license_number_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        // console.log("เลขที่ใบอนุญาตประกอบกิจการ:", obj.factory_license_number);
        tmp[this.state.factory_pos].factory_license_number_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      // validate Factory Name factory_name_en
      if (obj.factory_name_en === "") {
        document
          .getElementById("factory_name_en_" + this.state.factory_pos)
          .focus();
        tmp[this.state.factory_pos].factory_name_en_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        // console.log("Factory Name:", obj.factory_name_en);
        tmp[this.state.factory_pos].factory_name_en_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }

      // validate ชื่อโรงงาน factory_name_th
      if (obj.factory_name_th === "") {
        document
          .getElementById("factory_name_th_" + this.state.factory_pos)
          .focus();
        tmp[this.state.factory_pos].factory_name_th_error = true;
        await this.setState({
          factory_form_obj: tmp
        });
      } else {
        count++;
        // console.log("ชื่อโรงงาน:", obj.factory_name_th);
        tmp[this.state.factory_pos].factory_name_th_error = false;
        await this.setState({
          factory_form_obj: tmp
        });
      }
    }
    valid += 17;
    // console.log("valid", valid);
    // console.log("count:", count);
    if (valid === count) {
      await this.setState({
        formProductError: false
      });

      return true;
    } else {
      await this.setState({
        formProductError: true
      });

      return false;
    }
  };

  productFactorySubmit = async e => {
    // console.log("ip:", PRODUCT_TYPE);
    // console.log(this.state.product_type_options);
    // console.log(this.state.product_structure_options);
    var form_validate = await this.validFormByObj(
      this.state.factory_form_obj[this.state.factory_pos]
    );

    if (form_validate) {
      // console.log("OK");
      this.redicrectNextPage();
    }
    // else {
    //   this.redicrectNextPage();
    // }
    // console.log("form", form_validate);
  };

  redicrectNextPage = () => {
    this.goNextRedux();
    if (this.props.stateRedux.productImport) {

      this.props.history.push(`/company/editEntrepreneur/factoryImport`);
    }
    else {
      this.props.history.push(`/company/editEntrepreneur/authorizedPerson`);
    }
  };

  render() {
    var CurrentDate = moment();
    let factory_form_pos = this.state.factory_pos;

    const chooseIndustrial = this.state.factory_form_obj;
    let industrialLocation;
    let industrialDetail;

    if (chooseIndustrial[factory_form_pos].isIndustrial === "in") {
      industrialLocation = (
        <div style={{ marginBottom: 20 }}>

          <Form.Field>
            <label>นิคมอุตสาหกรรม</label>

            <Dropdown
              selection
              wrapSelection={false}
              options={this.state.factory_in_location_options}
              value={
                this.state.factory_form_obj[factory_form_pos].posIndustrial
              }
              onChange={async (e, { value }) => {

                this.state.index_factory_location_options[factory_form_pos] = value.pos - 1
                var tmp = this.state.factory_form_obj;

                tmp[factory_form_pos].posIndustrial = value;

                tmp[factory_form_pos].factory_number = "";
                tmp[factory_form_pos].factory_alley = "";
                tmp[factory_form_pos].factory_road = "";
                tmp[factory_form_pos].factory_province = value.province;
                tmp[factory_form_pos].factory_district = value.district;
                tmp[factory_form_pos].factory_zone = value.zone;
                tmp[factory_form_pos].factory_zip_code = value.zip_code;
                await this.setState({
                  factory_form_obj: tmp
                });
              }}
              name="factory_in_location"
            />
          </Form.Field>
        </div>
      );
    }

    let industrialDetailOut = (
      <div style={{ marginBottom: 20 }}>
        <Form.Field
          error={
            this.state.factory_form_obj[factory_form_pos].factory_number_error
          }
        >
          <label>
            เลขที่ <span style={{ color: "red" }}>*</span>
          </label>
          <Input
            id={"factory_number_" + factory_form_pos}
            icon='point'
            iconPosition='left'
            value={this.state.factory_form_obj[factory_form_pos].factory_number}
            onChange={(e, { value }) => {
              var tmp = this.state.factory_form_obj;
              tmp[factory_form_pos].factory_number = value;
              this.setState({
                factory_form_obj: tmp
              });
            }}
          />
          <p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
            หมายเหตุ : กรอกเลขที่และหมู่ที่หากมี
					</p>
        </Form.Field>
        <Form.Field
          error={
            this.state.factory_form_obj[factory_form_pos].factory_alley_error
          }
        >
          <label>
            ซอย <span style={{ color: "red" }}>*</span>
          </label>
          <Input
            id={"factory_alley_" + factory_form_pos}
            icon='compress'
            iconPosition='left'
            value={this.state.factory_form_obj[factory_form_pos].factory_alley}
            onChange={(e, { value }) => {
              var tmp = this.state.factory_form_obj;
              tmp[factory_form_pos].factory_alley = value;
              this.setState({
                factory_form_obj: tmp
              });
            }}
          />
        </Form.Field>
        <Form.Field
          error={
            this.state.factory_form_obj[factory_form_pos].factory_road_error
          }
        >
          <label>
            ถนน <span style={{ color: "red" }}>*</span>
          </label>
          <Input
            id={"factory_road_" + factory_form_pos}
            icon='road'
            iconPosition='left'
            value={this.state.factory_form_obj[factory_form_pos].factory_road}
            onChange={(e, { value }) => {
              var tmp = this.state.factory_form_obj;
              tmp[factory_form_pos].factory_road = value;
              this.setState({
                factory_form_obj: tmp
              });
            }}
          />
        </Form.Field>
        <Form.Field error={this.state.factory_form_obj[factory_form_pos].factory_province_error}>
          จังหวัด <span style={{ color: "red" }}>*</span>
          <Dropdown
            fluid
            placeholder='เลือกจังหวัด'
            selection
            clearable
            id={"factory_province_" + factory_form_pos}
            name={'factory_province_' + factory_form_pos}
            options={this.state.optionDropdown.dataprovince[factory_form_pos]}
            value={
              this.state.factory_form_obj[factory_form_pos].factory_province
            }
            onChange={(e, { value }) => {
              var tmp = this.state.factory_form_obj;
              tmp[factory_form_pos].factory_province = value;
              tmp[factory_form_pos].factory_district = '';
              tmp[factory_form_pos].factory_zone = '';
              tmp[factory_form_pos].factory_zip_code = '';
              this.state.optionDropdown.datadistrict[factory_form_pos] = ''
              this.state.optionDropdown.dataSubDistrict[factory_form_pos] = ''
              if (value !== '') {
                let position = this.state.optionDropdown.dataprovince[factory_form_pos].map((data) => data.value).indexOf(value);
                this.GetDistrict(this.state.optionDropdown.dataprovince[factory_form_pos][position].key, factory_form_pos);
              }
              this.setState({
                factory_form_obj: tmp
              });
            }}
          />
        </Form.Field>
        <Form.Field error={this.state.factory_form_obj[factory_form_pos].factory_district_error}>
          อำเภอ/เขต <span style={{ color: "red" }}>*</span>
          <Dropdown
            fluid
            placeholder='เลือกอำเภอ/เขต'
            selection
            clearable
            options={this.state.optionDropdown.datadistrict[factory_form_pos]}
            id={"factory_district_" + factory_form_pos}
            value={
              this.state.factory_form_obj[factory_form_pos].factory_district
            }
            onChange={(e, { value }) => {
              var tmp = this.state.factory_form_obj;
              tmp[factory_form_pos].factory_district = value;
              tmp[factory_form_pos].factory_zone = '';
              tmp[factory_form_pos].factory_zip_code = '';
              this.state.optionDropdown.dataSubDistrict[factory_form_pos] = ''
              if (value !== '') {
                let position = this.state.optionDropdown.datadistrict[factory_form_pos].map((data) => data.value).indexOf(value);
                this.GetSubDistrict(this.state.optionDropdown.datadistrict[factory_form_pos][position].key, factory_form_pos);
              }

              this.setState({
                factory_form_obj: tmp
              });
            }}
          />
        </Form.Field>
        <Form.Field error={this.state.factory_form_obj[factory_form_pos].factory_zone_error}>
          ตำบล/แขวง <span style={{ color: "red" }}>*</span>
          <Dropdown
            fluid
            placeholder='เลือกตำบล/แขวง'
            selection
            clearable
            id={"factory_zone_" + factory_form_pos}
            options={this.state.optionDropdown.dataSubDistrict[factory_form_pos]}
            value={this.state.factory_form_obj[factory_form_pos].factory_zone}
            onChange={(e, { value }) => {
              var tmp = this.state.factory_form_obj;
              tmp[factory_form_pos].factory_zone = value;
              tmp[factory_form_pos].factory_zip_code = '';
              if (value !== '') {
                let position = this.state.optionDropdown.dataSubDistrict[factory_form_pos].map((data) => data.value).indexOf(value);
                this.GetPostCode(this.state.optionDropdown.dataSubDistrict[factory_form_pos][position].key, factory_form_pos);
              }
              this.setState({
                factory_form_obj: tmp
              });
            }}
          />
        </Form.Field>

        <Form.Field
          error={
            this.state.factory_form_obj[factory_form_pos].factory_zip_code_error
          }
        >
          <label>
            รหัสไปรษณีย์ <span style={{ color: "red" }}>*</span>
          </label>
          <Input
            id={"factory_zip_code_" + factory_form_pos}
            icon='envelope'
            iconPosition='left'
            value={
              this.state.factory_form_obj[factory_form_pos].factory_zip_code
            }
            maxLength={5}
            onChange={(e, { value }) => {
              var tmp = this.state.factory_form_obj;
              tmp[factory_form_pos].factory_zip_code = value;
              this.setState({
                factory_form_obj: tmp
              });
            }}
          />
        </Form.Field>
      </div>
    )

    industrialDetail = (
      <div style={{ marginBottom: 20 }}>
        <Form.Field
          error={
            this.state.factory_form_obj[factory_form_pos].factory_number_error
          }
        >
          <label>
            เลขที่ <span style={{ color: "red" }}>*</span>
          </label>
          <Input
            id={"factory_number_" + factory_form_pos}
            icon='point'
            iconPosition='left'
            value={this.state.factory_form_obj[factory_form_pos].factory_number}
            onChange={(e, { value }) => {
              var tmp = this.state.factory_form_obj;
              tmp[factory_form_pos].factory_number = value;
              this.setState({
                factory_form_obj: tmp
              });
            }}
          />
        </Form.Field>
        <Form.Field
          error={
            this.state.factory_form_obj[factory_form_pos].factory_alley_error
          }
        >
          <label>
            ซอย <span style={{ color: "red" }}>*</span>
          </label>
          <Input
            id={"factory_alley_" + factory_form_pos}
            icon='compress'
            iconPosition='left'
            value={this.state.factory_form_obj[factory_form_pos].factory_alley}
            onChange={(e, { value }) => {
              var tmp = this.state.factory_form_obj;
              tmp[factory_form_pos].factory_alley = value;
              this.setState({
                factory_form_obj: tmp
              });
            }}
          />
        </Form.Field>
        <Form.Field
          error={
            this.state.factory_form_obj[factory_form_pos].factory_road_error
          }
        >
          <label>
            ถนน <span style={{ color: "red" }}>*</span>
          </label>
          <Input
            id={"factory_road_" + factory_form_pos}
            icon='road'
            iconPosition='left'
            value={this.state.factory_form_obj[factory_form_pos].factory_road}
            onChange={(e, { value }) => {
              var tmp = this.state.factory_form_obj;
              tmp[factory_form_pos].factory_road = value;
              this.setState({
                factory_form_obj: tmp
              });
            }}
          />
        </Form.Field>
        <Form.Field
          error={
            this.state.factory_form_obj[factory_form_pos].factory_province_error
          }
        >
          <label>
            จังหวัด <span style={{ color: "red" }}>*</span>
          </label>
          <Input
            id={"factory_province_" + factory_form_pos}
            value={
              this.state.factory_form_obj[factory_form_pos].factory_province
            }
            onChange={(e, { value }) => {
              var tmp = this.state.factory_form_obj;
              tmp[factory_form_pos].factory_province = value;
              this.setState({
                factory_form_obj: tmp
              });
            }}
          />
        </Form.Field>
        <Form.Field
          error={
            this.state.factory_form_obj[factory_form_pos].factory_district_error
          }
        >
          <label>
            อำเภอ/เขต <span style={{ color: "red" }}>*</span>
          </label>
          <Input
            id={"factory_district_" + factory_form_pos}
            value={
              this.state.factory_form_obj[factory_form_pos].factory_district
            }
            onChange={(e, { value }) => {
              var tmp = this.state.factory_form_obj;
              tmp[factory_form_pos].factory_district = value;
              this.setState({
                factory_form_obj: tmp
              });
            }}
          />
        </Form.Field>
        <Form.Field
          error={
            this.state.factory_form_obj[factory_form_pos].factory_zone_error
          }
        >
          <label>
            ตำบล/แขวง <span style={{ color: "red" }}>*</span>
          </label>
          <Input
            id={"factory_zone_" + factory_form_pos}
            value={this.state.factory_form_obj[factory_form_pos].factory_zone}
            onChange={(e, { value }) => {
              var tmp = this.state.factory_form_obj;
              tmp[factory_form_pos].factory_zone = value;
              this.setState({
                factory_form_obj: tmp
              });
            }}
          />
        </Form.Field>
        <Form.Field
          error={
            this.state.factory_form_obj[factory_form_pos].factory_zip_code_error
          }
        >
          <label>
            รหัสไปรษณีย์ <span style={{ color: "red" }}>*</span>
          </label>
          <Input
            id={"factory_zip_code_" + factory_form_pos}
            value={
              this.state.factory_form_obj[factory_form_pos].factory_zip_code
            }
            icon='envelope'
            iconPosition='left'
            maxLength={5}
            onChange={(e, { value }) => {
              var tmp = this.state.factory_form_obj;
              tmp[factory_form_pos].factory_zip_code = value;
              this.setState({
                factory_form_obj: tmp
              });
            }}
          />
        </Form.Field>
      </div>
    );

    let ProductFromFactory = [];
    this.state.factory_form_obj[factory_form_pos].factory_product.forEach(
      (product, index) => {
        let productIndex = index;
        let ProductTradeName = [];

        product.product_trade_name.forEach((trade, index) => {
          let tradeIndex = index;
          let mapTrade = (
            <Grid.Column mobile={16} tablet={16} computer={5}>
              <Form.Field
                error={
                  this.state.factory_form_obj[factory_form_pos].factory_product[
                    productIndex
                  ].product_trade_name[tradeIndex].trade_name_error
                }
              >
                <label>
                  ชื่อทางการค้า {tradeIndex + 1}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <Autocomplete suggestions={this.state.suggestion_option} onChange={(value)=>{
                      var tmp = this.state.factory_form_obj;
                      tmp[factory_form_pos].factory_product[productIndex].product_trade_name[tradeIndex].trade_name = value.toUpperCase();

                      this.setState({
                        factory_form_obj: tmp
                      });
                    }} values={trade.trade_name}
                />
                {/* <Dropdown
                  placeholder="เลือกชื่อทางการค้า"
                  fluid
                  search
                  selection
                  allowAdditions
                  additionLabel='เพิ่มชื่อ : ' 
                  options={this.state.suggestion_option}
                  value={trade.trade_name}
                  onAddItem={(e, { value }) => {
                    this.setState(prevState => ({
                      suggestion_option: [
                        ...prevState.suggestion_option,
                        { key: value, text: value, value: value }
                      ]
                    }));
                  }}
                  onChange={(e, { value }) => {
                    var tmp = this.state.factory_form_obj;
                    tmp[factory_form_pos].factory_product[
                      productIndex
                    ].product_trade_name[tradeIndex].trade_name = value;
                    this.setState({
                      factory_form_obj: tmp
                    });
                  }}
                /> */}
                  {/* {product.product_trade_name.length === 1 ? (
                    <Input
                      id={"trade_name_" + factory_form_pos}
                      value={trade.trade_name}
                      onChange={async (e, { value }) => {
                        var tmp = this.state.factory_form_obj;
                        tmp[factory_form_pos].factory_product[
                          productIndex
                        ].product_trade_name[tradeIndex].trade_name = value.toUpperCase();

                        await this.setState({
                          factory_form_obj: tmp
                        });
                      }}

                    />
                  ) : (
                      <Input
                        id={"trade_name_" + factory_form_pos}
                        value={trade.trade_name}
                        onChange={async (e, { value }) => {
                          var tmp = this.state.factory_form_obj;
                          tmp[factory_form_pos].factory_product[
                            productIndex
                          ].product_trade_name[tradeIndex].trade_name = value.toUpperCase();

                          await this.setState({
                            factory_form_obj: tmp
                          });
                        }}
                        action={{
                          icon: "close",
                          onClick: () => {
                            this.deleteTradeName(productIndex, tradeIndex);
                          },
                          type: "button"
                        }}
                      />
                    )} */}
              </Form.Field>
            </Grid.Column>
          );
          ProductTradeName.push(mapTrade);
        });

        let product_options = this.state.product_type_options;

        let mapProduct = (
          <Segment padded="very" color={theme_form_color_1}>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={6}>
                  <Form.Field
                    error={
                      this.state.factory_form_obj[factory_form_pos]
                        .factory_product[index].product_name_error
                    }
                  >
                    <label>
                      ผลิตภัณฑ์ที่ {index + 1}
                      <span style={{ color: "red" }}>*</span>
                    </label>

                    <Dropdown
                      selection
                      wrapSelection={false}
                      options={product_options}
                      id={"product_name_" + factory_form_pos + "_" + index}
                      placeholder="เลือกผลิตภัณฑ์"
                      value={
                        this.state.factory_form_obj[factory_form_pos]
                          .factory_product[index].product_name
                      }
                      onChange={async (e, { value }) => {
                        var tmp = this.state.factory_form_obj;
                        tmp[factory_form_pos].factory_product[
                          index
                        ].product_name = value;

                        await this.setState({
                          factory_form_obj: tmp,
                          // product_structure_options: product_structure_options
                        });
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={6}>
                  <Form.Field
                    error={
                      this.state.factory_form_obj[factory_form_pos]
                        .factory_product[index].product_structure_error
                    }
                  >
                    <label>
                      โครงสร้าง
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    เลือก &nbsp;
                    <Dropdown
                      style={{ width: '290px' }}
                      selection
                      wrapSelection={false}
                      options={
                        this.state.product_structure_options[
                        product_options.map((data) => data.value).indexOf(this.state.factory_form_obj[factory_form_pos].factory_product[
                          index
                        ].product_name)
                        ]
                      }
                      id={"product_structure_" + factory_form_pos + "_" + index}
                      placeholder="โครงสร้าง"
                      value={
                        this.state.factory_form_obj[factory_form_pos]
                          .factory_product[index].product_structure
                      }
                      onChange={async (e, { value }) => {
                        var tmp = this.state.factory_form_obj;
                        tmp[factory_form_pos].factory_product[
                          index
                        ].product_structure = value;

                        await this.setState({
                          factory_form_obj: tmp
                        });
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={4}>
                  <Form.Field
                    error={
                      this.state.factory_form_obj[factory_form_pos]
                        .factory_product[index].product_power_output_error
                    }
                  >
                    <label>
                      กำลังการผลิตทั้งหมด (เส้น/ปี)
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <Input
                      // type="number"
                      id={"product_power_output_" + factory_form_pos}
                      // ref={input => {
                      //   this.state.factory_form_obj[
                      //     factory_form_pos
                      //   ].factory_product[
                      //     index
                      //   ].product_power_output_focus = input;
                      // }}
                      value={
                        this.state.factory_form_obj[factory_form_pos]
                          .factory_product[index].product_power_output
                      }
                      onChange={async (e, { value }) => {
                        var tmp = this.state.factory_form_obj;

                        tmp[factory_form_pos].factory_product[
                          index
                        ].product_power_output = value
                          .replace(/\D/g, "")
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                        await this.setState({
                          factory_form_obj: tmp
                        });
                      }}
                    />
                    <p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
                      หมายเหตุ : กรอกเฉพาะตัวเลข
                    </p>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                {/* add here */}
                {ProductTradeName}
                {/* add here */}
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Button
                    icon
                    labelPosition="left"
                    type="button"
                    color={theme_form_color_2}
                    floated="left"
                    onClick={e => this.appendTradeName(index)}
                  >
                    เพิ่มชื่อทางการค้า
                    <Icon name="plus" />
                  </Button>

                  {this.state.factory_form_obj[factory_form_pos].factory_product.length > 1 && (
                    <Button
                      type="button"
                      color="red"
                      floated="right"
                      onClick={e => this.deleteProduct(index)}
                    >
                      ลบผลิตภัณฑ์นี้
                    </Button>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        );
        ProductFromFactory.push(mapProduct);
      }
    );

    let factory_form_obj_length = this.state.factory_form_obj.length;
    let factory_form_button;
    if (factory_form_pos === 0 && factory_form_obj_length === 1) {
      factory_form_button = (
        <div>
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              this.previousForm();
            }}
            floated="left"
          >
            กลับไปหน้าที่แล้ว
            <Icon name="left arrow" />
          </Button>

          <Button
            icon
            labelPosition="left"
            type="submit"
            color={theme_button_color}
            floated="right"
            onClick={this.productFactorySubmit}
          >
            ไปหน้าถัดไป
            <Icon name="right arrow" />
          </Button>

          <Button
            icon
            labelPosition="left"
            type="submit"
            primary
            floated="right"
            onClick={this.appendForm}
          >
            เพิ่มโรงงาน (ผลิต)
            <Icon name="plus" />
          </Button>

        </div>
      );
    } else if (factory_form_pos === 0) {
      factory_form_button = (
        <div>
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              this.previousForm();
            }}
            floated="left"
          >
            กลับไปหน้าที่แล้ว
            <Icon name="left arrow" />
          </Button>
          <Button
            icon
            labelPosition="left"
            type="submit"
            color={theme_button_color}
            floated="right"
            onClick={this.nextForm}
          >
            ถัดไป
            <Icon name="right arrow" />
          </Button>
          <Button
            icon
            labelPosition="left"
            type="submit"
            color="red"
            floated="right"
            disabled
          >
            ลบโรงงาน (ผลิต)
            <Icon name="trash alternate" />
          </Button>
        </div>
      );
    } else if (factory_form_pos < factory_form_obj_length - 1) {
      factory_form_button = (
        <div>
          <Button
            icon
            labelPosition="left"
            color={theme_button_color}
            onClick={this.backForm}
            floated="left"
          >
            กลับไปหน้าที่แล้ว
            <Icon name="left arrow" />
          </Button>

          <Button
            icon
            labelPosition="left"
            type="submit"
            color={theme_button_color}
            floated="right"
            onClick={this.nextForm}
          >
            ถัดไป
            <Icon name="right arrow" />
          </Button>
          <Button
            icon
            labelPosition="left"
            type="submit"
            color="red"
            floated="right"
            onClick={this.deleteIndustrial}
          >
            ลบโรงงาน (ผลิต)
            <Icon name="trash alternate" />
          </Button>
        </div>
      );
    } else if (factory_form_pos === factory_form_obj_length - 1) {
      factory_form_button = (
        <div>
          <Button
            icon
            labelPosition="left"
            color={theme_button_color}
            onClick={this.backForm}
            floated="left"
          >
            กลับไปหน้าที่แล้ว
            <Icon name="left arrow" />
          </Button>
          <Button
            icon
            labelPosition="left"
            type="submit"
            color={theme_button_color}
            floated="right"
            onClick={this.productFactorySubmit}
          >
            ไปหน้าถัดไป
            <Icon name="right arrow" />
          </Button>
          <Button
            icon
            labelPosition="left"
            type="submit"
            primary
            floated="right"
            onClick={this.appendForm}
          >
            เพิ่มโรงงาน (ผลิต)
            <Icon name="plus" />
          </Button>
          <Button
            icon
            labelPosition="left"
            type="submit"
            color="red"
            floated="right"
            onClick={this.deleteIndustrial}
          >
            ลบโรงงาน (ผลิต)
            <Icon name="trash alternate" />
          </Button>
        </div>
      );
    }

    return (
      <div>
        <div>
          <Headers />
          <Container id="headFactory">
            <Form
              // error={this.state.factory_form_obj[factory_form_pos].formProductError}
              error={this.state.formProductError}
            // success={true}
            >
              <br />
              <Segment padded="very" color={theme_form_color_1}>
                <Header>
                  <h1>
                    <Icon color="violet" name="add circle" />
                    ข้อมูลการผลิต
                  </h1>
                </Header>

                <Divider />
                <Grid>
                  <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <h3>
                        {this.state.factory_form_obj.length > 1 ? (
                          <span>
                            ข้อมูลโรงงานที่ {this.state.factory_pos + 1}
                          </span>
                        ) : (
                            <span>ข้อมูลโรงงาน</span>
                          )}
                      </h3>

                      <Form.Field
                        error={
                          this.state.factory_form_obj[factory_form_pos]
                            .factory_name_th_error
                        }
                      >
                        <label>
                          ชื่อโรงงานภาษาไทย
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          id={"factory_name_th_" + factory_form_pos}
                          icon="factory"
                          iconPosition="left"
                          name="factory_name_th"
                          value={
                            this.state.factory_form_obj[factory_form_pos]
                              .factory_name_th
                          }
                          onChange={async (e, { value }) => {
                            var tmp = this.state.factory_form_obj;
                            tmp[factory_form_pos].factory_name_th = value;
                            await this.setState({
                              factory_form_obj: tmp
                            });
                          }}
                        />
                        <p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
                          หมายเหตุ : กรอกชื่อโรงงานภาษาไทย
								        </p>
                      </Form.Field>

                      <Form.Field
                        error={
                          this.state.factory_form_obj[factory_form_pos]
                            .factory_name_en_error
                        }
                      >
                        <label>
                          ชื่อโรงงานภาษาอังกฤษ
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          id={"factory_name_en_" + factory_form_pos}
                          icon="factory"
                          iconPosition="left"
                          value={
                            this.state.factory_form_obj[factory_form_pos]
                              .factory_name_en
                          }
                          onChange={async (e, { value }) => {
                            var tmp = this.state.factory_form_obj;
                            tmp[factory_form_pos].factory_name_en = value;
                            await this.setState({
                              factory_form_obj: tmp
                            });
                          }}
                        />
                        <p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
                          หมายเหตุ : กรอกชื่อโรงงานภาษาอังกฤษ
                        </p>
                      </Form.Field>

                      <Form.Field
                        error={
                          this.state.factory_form_obj[factory_form_pos]
                            .factory_license_number_error
                        }
                      >
                        <label>
                          ทะเบียนโรงงานเลขที่
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          id={"factory_license_number_" + factory_form_pos}
                          icon="drivers license"
                          iconPosition="left"
                          value={
                            this.state.factory_form_obj[factory_form_pos]
                              .factory_license_number
                          }
                          onChange={async (e, { value }) => {
                            var tmp = this.state.factory_form_obj;
                            tmp[
                              factory_form_pos
                            ].factory_license_number = value;
                            await this.setState({
                              factory_form_obj: tmp
                            });
                          }}
                        />
                      </Form.Field>

                      <Form.Field
                        error={
                          this.state.factory_form_obj[factory_form_pos]
                            .factory_certificate_error
                        }
                      >
                        <label>
                          ได้รับใบรับรองระบบบริหารงานคุณภาพ
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Dropdown
                          selection
                          wrapSelection={false}
                          name="factory_certificate"
                          placeholder="Choose an Factory License"
                          id="factory_certificate"
                          options={this.state.datastandard}
                          value={this.state.factory_form_obj[factory_form_pos]
                            .factory_certificate}
                          onChange={(e, { name, value }) => {
                            var tmp = this.state.factory_form_obj;
                            tmp[factory_form_pos].factory_certificate = value;
                            this.setState({
                              factory_form_obj: tmp
                            });
                          }}
                        />
                        <p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
                          หมายเหตุ : หากมีมากกว่า 1 ระบบ ให้เลือกระบบที่ใบรับรองหมดอายุช้ากว่า
								        </p>
                      </Form.Field>

                      <Grid>
                        <Grid.Row>
                          <Grid.Column mobile={16} tablet={16} computer={8}>
                            <Form.Field
                              error={
                                this.state.factory_form_obj[factory_form_pos]
                                  .factory_from_error
                              }
                            >
                              <label>
                                จาก
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Input
                                id={"factory_from_" + factory_form_pos}
                                name="factory_from"
                                value={
                                  this.state.factory_form_obj[factory_form_pos]
                                    .factory_from
                                }
                                onChange={async (e, { value }) => {
                                  var tmp = this.state.factory_form_obj;
                                  tmp[factory_form_pos].factory_from = value;
                                  await this.setState({
                                    factory_form_obj: tmp
                                  });
                                }}
                              />
                              <p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
                                หมายเหตุ : กรอกชื่อหน่วยงานผู้ให้การรับรอง (Certification Body : CB)
								              </p>
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column mobile={16} tablet={16} computer={8}>
                            <Form.Field
                              style={{ marginBottom: 15 }}
                              error={
                                this.state.factory_form_obj[factory_form_pos]
                                  .factory_under_error
                              }
                            >
                              <label>
                                ภายใต้
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Input
                                name="factory_under"
                                id={"factory_under_" + factory_form_pos}
                                value={
                                  this.state.factory_form_obj[factory_form_pos]
                                    .factory_under
                                }
                                onChange={async (e, { value }) => {
                                  var tmp = this.state.factory_form_obj;
                                  tmp[factory_form_pos].factory_under = value;
                                  await this.setState({
                                    factory_form_obj: tmp
                                  });
                                }}
                              />
                              <p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
                                หมายเหตุ : กรอกชื่อผู้ตรวจประเมิณและให้การรับรอง CB (Accreditation Body : AB)
								              </p>
                            </Form.Field>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      <Form.Field error={
                        this.state.factory_form_obj[factory_form_pos]
                          .factory_expire_certificate_error
                      }>
                        <label>
                          วันหมดอายุใบรับรองระบบบริหารงานคุณภาพ<span style={{ color: "red" }}>*</span></label>
                        <DateInput
                          minDate={CurrentDate}
                          fluid
                          placeholder='วันหมดอายุใบรับรองระบบบริหารงานคุณภาพ'
                          iconPosition='left'
                          startMode='year'
                          dateFormat='DD/MM/YYYY'
                          popupPosition='bottom center'
                          animation='glow'
                          duration={100}
                          closable={true}
                          clearable={true}
                          marked={new Date(Date.now())}
                          markColor='violet'
                          hideMobileKeyboard={true}
                          inline={false}
                          value={
                            this.state.factory_form_obj[factory_form_pos]
                              .factory_expire_certificate
                          }
                          name='expire_certificate'
                          id={"factory_expire_certificate"}
                          onChange={async (e, { value }) => {
                            var tmp = [...this.state.factory_form_obj];
                            
                            if (!tmp[factory_form_pos].factory_expire_certificate_TEMP) {
                              tmp[factory_form_pos].factory_expire_certificate_TEMP = tmp[factory_form_pos].factory_expire_certificate
                            }

                            tmp[factory_form_pos].factory_expire_certificate = value;
                            var dateValid = moment(value, "DD/MM/YYYY", true).isValid()

                            if (dateValid) {
                              tmp[factory_form_pos].factory_expire_certificate_TEMP = value;
                            }
                            this.setState({
                              factory_form_obj: tmp
                            });
                          }}

                          onBlur={() => {
                            var tmp = this.state.factory_form_obj;
                            if (!moment(tmp[factory_form_pos].factory_expire_certificate).isValid() && tmp[factory_form_pos].factory_expire_certificate !== '') {

                              tmp[factory_form_pos].factory_expire_certificate = tmp[factory_form_pos].factory_expire_certificate_TEMP;
                              this.setState({
                                factory_form_obj: tmp
                              });
                            }

                          }
                          }

                        />
                      </Form.Field>
                      <Form.Field>
                        <label>
                          ที่ตั้งโรงงาน
                          <span style={{ color: "red" }}>*</span>
                        </label>

                        <Dropdown
                          selection
                          wrapSelection={false}
                          options={this.state.factory_location_options}
                          name="isIndustrial"
                          placeholder="Choose an Factory License"
                          id={"isIndustrial_" + factory_form_pos}
                          value={
                            this.state.factory_form_obj[factory_form_pos]
                              .isIndustrial
                          }
                          onChange={async (e, { value, text }) => {

                            var tmp = this.state.factory_form_obj;
                            tmp[factory_form_pos].isIndustrial = value;
                            tmp[factory_form_pos].posIndustrial =
                              this.state.factory_in_location_options[this.state.index_factory_location_options[factory_form_pos]].value;

                            if (value === "in") {
                              tmp[factory_form_pos].factory_location =
                                "ในเขตนิคมอุตสาหกรรม";
                              tmp[factory_form_pos].factory_number = "";
                              tmp[factory_form_pos].factory_alley = "";
                              tmp[factory_form_pos].factory_road = "";
                              tmp[factory_form_pos].factory_province =
                                this.state.factory_in_location_options[this.state.index_factory_location_options[factory_form_pos]].value.province;
                              tmp[factory_form_pos].factory_district =
                                this.state.factory_in_location_options[this.state.index_factory_location_options[factory_form_pos]].value.district;
                              tmp[factory_form_pos].factory_zone =
                                this.state.factory_in_location_options[this.state.index_factory_location_options[factory_form_pos]].value.zone;
                              tmp[factory_form_pos].factory_zip_code =
                                this.state.factory_in_location_options[this.state.index_factory_location_options[factory_form_pos]].value.zip_code;
                            } else if (value === "out") {
                              tmp[factory_form_pos].posIndustrial = { pos: 0 }
                              tmp[factory_form_pos].factory_location =
                                "นอกเขตนิคมอุตสาหกรรม";
                              tmp[factory_form_pos].factory_number = "";
                              tmp[factory_form_pos].factory_alley = "";
                              tmp[factory_form_pos].factory_road = "";
                              tmp[factory_form_pos].factory_province = tmp[
                                factory_form_pos
                              ].factory_district = "";
                              tmp[factory_form_pos].factory_zone = "";
                              tmp[factory_form_pos].factory_zip_code = "";
                            }
                            await this.setState({
                              factory_form_obj: tmp
                            });
                          }}
                        />
                      </Form.Field>

                      <div>
                        {industrialLocation}
                        {chooseIndustrial[factory_form_pos].isIndustrial === 'in' ? industrialDetail : industrialDetailOut}
                      </div>

                      <Form.Field
                        error={
                          this.state.factory_form_obj[factory_form_pos]
                            .factory_tel_error
                        }
                      >
                        <label>
                          เบอร์โทรศัพท์
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          id={"factory_tel_" + factory_form_pos}
                          icon="phone"
                          iconPosition="left"
                          name="factory_tel"
                          maxLength={10}
                          value={
                            this.state.factory_form_obj[factory_form_pos]
                              .factory_tel
                          }
                          onChange={async (e, { value }) => {
                            var tmp = this.state.factory_form_obj;
                            tmp[factory_form_pos].factory_tel = value.replace(/\D/g, '');
                            await this.setState({
                              factory_form_obj: tmp
                            });
                          }}
                        />
                        <p style={{ color: '#db2828', fontWeight: 'bold', paddingTop: '1rem' }}>
                          หมายเหตุ : กรอกเบอร์โทรศัพท์ โดยไม่ต้องกรอกเครื่องหมาย -
								        </p>
                      </Form.Field>
                      <h3>เอกสารแนบ</h3>

                      <Form.Field
                        error={
                          this.state.factory_form_obj[factory_form_pos]
                            .factory_license_file_error
                        }
                      >
                        <Grid>

                          <Grid.Column computer='4'>
                            <label>
                              ใบอนุญาตประกอบกิจการโรงงาน
                          <span style={{ color: "red" }}>*</span>
                            </label>
                          </Grid.Column>
                          <Grid.Column computer='8'>
                            <Segment id="factory_license_file_focus">
                              <Label
                                attached="top"
                                color={
                                  this.state.factory_form_obj[factory_form_pos]
                                    .factory_license_file_error
                                    ? "red"
                                    : "violet"
                                }
                                as="label"
                                htmlFor="factory_license_file"
                                size="large"
                              >
                                <Icon name="upload" />
                                เลือกไฟล์
                            <input
                                  hidden
                                  type="file"
                                  id="factory_license_file"
                                  name="factory_license_file"
                                  accept=".pdf"
                                  onChange={async e => {
                                    // console.log(e.target.files[0]);
                                    if (e.target.files[0].type === "application/pdf") {
                                      var tmp = this.state.factory_form_obj;

                                      if (e.target.files[0]) {
                                        if (e.target.files.size > 10000000) {
                                          alert("ไฟล์ใหญ่เกินที่กำหนดไว้");
                                          return;
                                        }
                                        // console.log(e.target.files[0]);
                                        var src = URL.createObjectURL(
                                          e.target.files[0]
                                        );
                                        // var id = e.target.id;
                                        // const form = new FormData();
                                        // form.append("test_file", e.target.files[0]);
                                        tmp[
                                          factory_form_pos
                                        ].factory_license_file.name =
                                          e.target.files[0].name;
                                        tmp[
                                          factory_form_pos
                                        ].factory_license_file.data =
                                          e.target.files[0];
                                        tmp[
                                          factory_form_pos
                                        ].factory_license_file.path = src;

                                        tmp[
                                          factory_form_pos
                                        ].factory_license_file_error = false;
                                        await this.setState({
                                          factory_form_obj: tmp
                                        });
                                        // console.log(id);
                                      }
                                    } else {
                                      Swal.fire({
                                        type: 'error',
                                        title: `ชนิดไฟล์ไม่ถูกต้อง 
                                  กรุณาตรวจสอบใหม่อีกครั้ง`
                                      });
                                    }

                                  }}
                                  onClick={(e) => { e.target.value = null }}
                                />
                              </Label>
                              <p>
                                {
                                  this.state.factory_form_obj[
                                    this.state.factory_pos
                                  ].factory_license_file.name
                                }
                                &nbsp; &nbsp;
                            {this.state.factory_form_obj[this.state.factory_pos]
                                  .factory_license_file.name ? (
                                    <Icon
                                      id="factory_license_file"
                                      color="red"
                                      size="large"
                                      name="times circle outline"
                                      link
                                      onClick={async e => {
                                        var tmp = this.state.factory_form_obj;
                                        tmp[factory_form_pos].factory_license_file = {
                                          name: "",
                                          data: "",
                                          path: ""
                                        };
                                        tmp[
                                          factory_form_pos
                                        ].factory_license_file_error = true;
                                        await this.setState({
                                          factory_form_obj: tmp
                                        });
                                        document.getElementById(
                                          "factory_license_file"
                                        ).value = null;
                                      }}
                                    />
                                  ) : null}
                              </p>
                            </Segment>
                            <p
                              style={{
                                color: "#db2828"
                              }}
                            >
                              ชนิดไฟล์ที่ใช้อัปโหลด : *.pdf ขนาดไฟล์ไม่เกิน 10 M
                        </p>
                          </Grid.Column>
                          <Grid.Column computer={4} tablet={4}>
                            <Label
                              size='large'
                              basic
                              color='red'
                              pointing='left'
                              style={{
                                display: this.state.factory_form_obj[factory_form_pos]
                                  .factory_license_file_error ? 'block' : 'none'
                              }}
                            >
                              <Icon name='warning circle' />
                              กรุณาเพิ่มไฟล์ !
                          </Label>
                          </Grid.Column>
                        </Grid>
                      </Form.Field>
                      <Form.Field
                        error={
                          this.state.factory_form_obj[factory_form_pos]
                            .factory_certificate_file_error
                        }
                      >
                        <Grid>
                          <Grid.Column computer={4}>
                            <label>
                              ใบรับรองระบบบริหารงานคุณภาพ
                          <span style={{ color: "red" }}>*</span>
                            </label>
                          </Grid.Column>
                          <Grid.Column computer={8}>
                            <Segment id="factory_certificate_file_focus">
                              <Label
                                attached="top"
                                color={
                                  this.state.factory_form_obj[factory_form_pos]
                                    .factory_certificate_file_error
                                    ? "red"
                                    : "violet"
                                }
                                as="label"
                                htmlFor="factory_certificate_file"
                                size="large"
                              >
                                <Icon name="upload" />
                                เลือกไฟล์
                            <input
                                  hidden
                                  type="file"
                                  id="factory_certificate_file"
                                  name="factory_certificate_file"
                                  accept=".pdf"
                                  onChange={async e => {
                                    // console.log(e.target.files[0]);
                                    if (e.target.files[0].type === "application/pdf") {
                                      var tmp = this.state.factory_form_obj;

                                      if (e.target.files[0]) {
                                        if (e.target.files.size > 10000000) {
                                          alert("ไฟล์ใหญ่เกินที่กำหนดไว้");
                                          return;
                                        }
                                        // console.log(e.target.files[0]);
                                        var src = URL.createObjectURL(
                                          e.target.files[0]
                                        );
                                        var id = e.target.id;
                                        // const form = new FormData();
                                        // form.append("test_file", e.target.files[0]);
                                        tmp[
                                          factory_form_pos
                                        ].factory_certificate_file.name =
                                          e.target.files[0].name;
                                        tmp[
                                          factory_form_pos
                                        ].factory_certificate_file.data =
                                          e.target.files[0];
                                        tmp[
                                          factory_form_pos
                                        ].factory_certificate_file.path = src;

                                        tmp[
                                          factory_form_pos
                                        ].factory_certificate_file_error = false;
                                        await this.setState({
                                          factory_form_obj: tmp
                                        });
                                        // console.log(id);
                                      }
                                    } else {
                                      Swal.fire({
                                        type: 'error',
                                        title: `ชนิดไฟล์ไม่ถูกต้อง 
                                  กรุณาตรวจสอบใหม่อีกครั้ง`
                                      });
                                    }
                                  }}
                                  onClick={(e) => { e.target.value = null }}
                                />
                              </Label>
                              <p>
                                {
                                  this.state.factory_form_obj[
                                    this.state.factory_pos
                                  ].factory_certificate_file.name
                                }
                                &nbsp; &nbsp;
                            {this.state.factory_form_obj[this.state.factory_pos]
                                  .factory_certificate_file.name ? (
                                    <Icon
                                      id="factory_certificate_file"
                                      color="red"
                                      size="large"
                                      name="times circle outline"
                                      link
                                      // onClick={this.handleUploadFileReset.bind(this)}
                                      onClick={async e => {
                                        var tmp = this.state.factory_form_obj;
                                        tmp[
                                          factory_form_pos
                                        ].factory_certificate_file = {
                                          name: "",
                                          data: "",
                                          path: ""
                                        };
                                        tmp[
                                          factory_form_pos
                                        ].factory_certificate_file_error = true;
                                        await this.setState({
                                          factory_form_obj: tmp
                                        });
                                        document.getElementById(
                                          "factory_certificate_file"
                                        ).value = null;
                                      }}
                                    />
                                  ) : null}
                              </p>
                            </Segment>
                            <p
                              style={{
                                color: "#db2828"
                              }}
                            >
                              ชนิดไฟล์ที่ใช้อัปโหลด : *.pdf ขนาดไฟล์ไม่เกิน 10 M
                        </p>
                          </Grid.Column>
                          <Grid.Column computer={4} tablet={4}>
                            <Label
                              size='large'
                              basic
                              color='red'
                              pointing='left'
                              style={{
                                display: this.state.factory_form_obj[factory_form_pos]
                                  .factory_certificate_file_error ? 'block' : 'none'
                              }}
                            >
                              <Icon name='warning circle' />
                              กรุณาเพิ่มไฟล์ !
                          </Label>
                          </Grid.Column>
                        </Grid>
                      </Form.Field>

                      <Segment color={theme_form_color_1}>
                        <h3>ข้อมูลผลิตภัณฑ์</h3>

                        {ProductFromFactory}

                        <Button
                          icon
                          labelPosition="left"
                          type="button"
                          color={theme_form_color_2}
                          floated="right"
                          onClick={this.appendProduct}
                        >
                          เพิ่มผลิตภัณฑ์
                          <Icon name="plus" />
                        </Button>
                        <br />
                        <br />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              {factory_form_button}
              <br />
              <br />
              <br />
              <br />
            </Form>
          </Container>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(productRegister);
